// extracted by mini-css-extract-plugin
export var content = "article-preview-module--content--IiIpv";
export var media = "article-preview-module--media--LYgey";
export var infoContent = "article-preview-module--infoContent--E1sPz";
export var content__horizontal = "article-preview-module--content__horizontal--IJp2y";
export var showVideoTag = "article-preview-module--showVideoTag--AgcJd";
export var hideVideoTag = "article-preview-module--hideVideoTag--ZynAQ";
export var title = "article-preview-module--title--PUnyo";
export var links = "article-preview-module--links--Q9m2B";
export var link = "article-preview-module--link--nGqEh";
export var time = "article-preview-module--time--gtmLl";